/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        // identify a click on a smooth scroll link, scroll page down
        // $('.smooth-scroll a, a.smooth-scroll').click(function() {
        //   var target = $(this).attr('href');
        //   // replace this with smooth scrolling code
        //   // scroll to target
        //   console.log('smooth scroll link clicked: ' + target);
        // }); 

        $('a[href*="#"]:not([href="#"])').click(function() {
          if (location.pathname.replace(/^\//,'') === this.pathname.replace(/^\//,'') && location.hostname === this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
            if (target.length) {
              $('html, body').animate({
                scrollTop: target.offset().top
              }, 1500);
              return false;
            }
          }
        });  

        var heroHeader = $(".hero-header .owl-carousel");
        var testSlider = $(".testimonial-slideshow-cta .owl-carousel");

        // initialise carousels if they're present
        heroHeader.owlCarousel({
          items: 1,
          autoplay: true
        });

        testSlider.owlCarousel({
          items: 1,
          loop: true
        });

        // nav toggle lark
        $('.nav-toggle').click(function() {
          $(this).toggleClass('active');
          $('nav.main-nav ').toggle();
        });

        // window resize functions
        $(window).resize(function() {
          var width = $(window).width();
          if(width >= 992) {
            $('nav.main-nav').show();
            $('.nav-toggle').removeClass('active');
          }
          if(width < 992) {
            $('nav.main-nav').hide();
          }
        });

        // navigation for the testimonials
        $('.owl-nav-prev').click(function() {
          testSlider.trigger('prev.owl.carousel', [300]);
        });

        $('.owl-nav-next').click(function() {
          testSlider.trigger('next.owl.carousel', [300]);
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
